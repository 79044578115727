@import "./mixin.scss";

@include sp {
    .pc {
        display: none;
    }
}

@include pc {
    .sp {
        display: none;
    }
}


.fv {

    &_group {
        @include pc {
            width: vw(1400);
            position: absolute;
            z-index: 100;
            top: vw(109);
            left: 50%;
            transform: translateX(-50%);
            display: grid;
            grid-template-columns: max-content 1fr;
            gap: vw(34);
        }

        @include sp {}
    }

    &_splide {
        &--main {
            width: vw(845.95);
            height: vw(786.23);
            transform: translateX(vw(-30));

            @include sp {
                width: vw(375, 375);
                height: vw(384, 375);
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                overflow: hidden;
            }
        }

        &--text {
            margin-top: vw(152);

            @include sp {
                margin-top: 0;
                position: absolute;
                left: vw(34, 375);
                top: vw(397, 375);
                z-index: 1000;
            }
        }
    }

    &_wrap {
        height: vw(916);
        position: relative;
        z-index: 1;
        width: 100vw;
        overflow-x: hidden;

        @include sp {
            height: vw(704.88, 375);
        }
    }

    &_img {
        // pointer-events: none;
        // display: none;

        &--main {
            @include size(100%);

            &_picture {
                width: vw(845.95);
                height: vw(786.23);
                display: block;

                @include sp {
                    position: relative;
                    top: vw(-24, 375);
                    width: vw(439, 375);
                    height: vw(408.01, 375);
                    left: 50%;
                    z-index: 1000;
                    transform: translateX(-50%);
                }
            }
        }

        &--text {
            @include state(index-1) {
                width: vw(482.8);
                height: vw(178.88);

                @include sp {
                    width: vw(309, 375);
                    height: vw(114.49, 375);
                }
            }

            @include state(index-2) {
                width: vw(498.83);
                height: vw(128.32);

                @include sp {
                    width: vw(302, 375);
                    height: vw(86, 375);
                }
            }

            @include state(index-3) {
                width: vw(302);
                height: vw(127.56);

                @include sp {
                    width: vw(181.62, 375);
                    height: vw(82, 375);
                }
            }

            &_picture {
                display: flex;
                align-items: flex-end;
                height: vw(178.88);
                position: relative;
                z-index: 1;

                @include sp {
                    height: vw(114.49, 375);
                }
            }
        }

        &--back {
            width: 100vw;
            height: vw(662);
            background-image: url(../images/fv/back.webp);
            background-size: cover;
            position: absolute;
            z-index: -50;
            top: 0;
            left: 0;

            @include sp {
                width: 100vw;
                height: vw(542, 375);
                background-image: url(../images/fv/back-sp.webp);
            }
        }

        &--right-top {
            width: vw(960);
            height: vw(817);
            background-image: url(../images/fv/right-top.webp);
            background-size: cover;
            position: absolute;
            z-index: -40;
            right: 0;
            top: 0;

            @include sp {
                width: vw(375, 375);
                height: vw(408, 375);
                background-image: url(../images/fv/right-top-sp.webp);
                top: unset;
                bottom: vw(9.5, 375);
            }
        }

        &--left-top {
            width: vw(464);
            height: vw(362);
            background-image: url(../images/fv/left-top.webp);
            background-size: cover;
            position: absolute;
            z-index: -40;
            left: 0;
            top: 0;

            @include sp {
                width: vw(91, 375);
                height: vw(126, 375);
                background-image: url(../images/fv/left-top-sp.webp);
                right: 0;
                left: unset;
                top: vw(174, 375);
                z-index: 10;
            }
        }

        &--left-bottom {
            width: vw(348);
            height: vw(292);
            background-image: url(../images/fv/left-bottom.webp);
            background-size: cover;
            position: absolute;
            z-index: 110;
            left: 0;
            bottom: vw(54);

            @include sp {
                background-image: url(../images/fv/left-bottom-sp.webp);
                width: vw(114, 375);
                height: vw(129, 375);
                bottom: vw(274.5, 375);
            }
        }

        &--left-media {
            width: vw(242.91);
            height: vw(233.49);
            background-image: url(../images/fv/left-top-media.webp);
            background-size: cover;
            position: absolute;
            z-index: -40;
            left: 0;
            top: vw(274);

            @include sp {
                background-image: url(../images/fv/left-top-media-sp.webp);
                width: vw(130, 375);
                height: vw(124.96, 375);
                left: vw(-5.9, 375);
                top: unset;
                bottom: vw(0);
            }
        }

        &--right-media {
            width: vw(211);
            height: vw(291);
            background-image: url(../images/fv/right-media.webp);
            background-size: cover;
            position: absolute;
            z-index: 20;
            right: 0;
            bottom: vw(127);

            @include sp {
                width: vw(67, 375);
                height: vw(104, 375);
                background-image: url(../images/fv/right-media-sp.webp);
                bottom: unset;
                top: vw(259, 375);
            }
        }

        &--stylish-text {
            width: vw(415.34);
            height: vw(124.21);
            background-image: url(../images/fv/stylish-text.webp);
            background-size: cover;
            position: absolute;
            z-index: 20;
            right: vw(193);
            bottom: vw(300.8);

            @include sp {
                width: vw(240, 375);
                height: vw(71.77, 375);
                right: 16px;
                bottom: vw(78.7, 375);
            }
        }

        &--dec-top {
            width: vw(161.04);
            height: vw(89.04);
            background-image: url(../images/fv/picture-dec-top.webp);
            background-size: cover;
            position: absolute;
            z-index: 120;
            left: 50vw;
            transform: translateX(calc(-50% + vw(20)));
            top: vw(216);

            @include sp {
                width: vw(71.68, 375);
                height: vw(39.63, 375);
                background-image: url(../images/fv/picture-dec-top-sp.webp);
                left: unset;
                right: vw(13.5, 375);
                top: vw(366.4, 375);
            }
        }

        &--dec-bottom {
            width: vw(110.86);
            height: vw(151.09);
            background-image: url(../images/fv/picture-dec-bottom.webp);
            background-size: cover;
            position: absolute;
            z-index: 120;
            left: 50vw;
            transform: translateX(calc(-50% + vw(101)));
            bottom: vw(178.1);

            @include sp {
                width: vw(49.34, 375);
                height: vw(67.25, 375);
                background-image: url(../images/fv/picture-dec-bottom-sp.webp);
                top: vw(263, 375);
                bottom: unset;
                left: vw(251.5, 375);
                transform: unset;
            }
        }
    }
}

.fixed {
    &_wrap {
        position: fixed;
        right: vw(53);
        bottom: vw(30);
        z-index: 1000;
        display: flex;
        align-items: center;
    }

    &_img {
        &--line {
            width: vw(200);
            height: vw(200);
            background-image: url(../images/common/fixed-line.webp);
            background-size: cover;
            position: relative;
            z-index: 1;
        }

        &--contact {
            width: vw(200);
            height: vw(200);
            background-image: url(../images/common/fixed-contact.webp);
            background-size: cover;
            position: relative;
            z-index: 10;
            transform: translateX(vw(23));
        }
    }
}

.header {
    height: 0px;

    @include pc {
        &.active {
            .header_link {
                color: #000;
                transition: color .3s ease;

                @include hover {
                    color: #639B54;
                }
            }
        }
    }

    @include sp {
        &.active {
            .header_img--logo__picture {
                width: 73.07px;
                height: 80px;
            }
        }
    }

    &_nav {
        position: fixed;
        top: 20px;
        right: 20px;
        height: 80px;
        display: grid;
        grid-template-columns: max-content max-content;
        gap: res(14px);
        z-index: 1000;
        align-items: center;

        @include sp {
            width: 100vw;
            height: 100vh;
            background-color: #639B54;
            position: fixed;
            right: 0;
            top: 0;
            padding: 120px 24px;
            overflow-y: scroll;
            grid-auto-rows: max-content;
            grid-template-columns: 100%;
            opacity: 0;
            transition: opacity .3s ease;
            pointer-events: none;

            &.is-open {
                opacity: 1;
                pointer-events: unset;
            }
        }
    }

    &_list {
        display: flex;
        gap: res(32px);
        align-items: center;

        @include sp {
            display: grid;
            grid-template-columns: 100%;
        }
    }

    &_items {
        display: flex;
        align-items: center;
        gap: res(32px);

        @include sp {
            justify-content: center;
            border-bottom: 1px solid white;
            padding-bottom: 32px;
        }

        @include dec(after) {
            @include size-res(11px, 41px);
            background-image: url(../images/common/slash-bar.webp);
            background-size: cover;

            @include sp {
                display: none;
            }
        }

        &:nth-last-child(1) {
            &::after {
                display: none;
            }
        }
    }

    &_link {
        font-size: 18px;
        color: white;
        font-weight: 500;
        text-decoration: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        &::before {
            content: attr(data-slug);
            display: block;
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;
        }
    }

    &_img {
        &--logo {
            @include size(100%);

            &__picture {
                @include size-res(194px, 196px);
                transition: transform .3s ease;
                transform-origin: left top;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 10000;

                @include sp {
                    @include size(116px, 127px);
                    transition: width .3s ease, height .3s ease;
                }

                @include hover {
                    transform: scale(1.04);
                }
            }
        }

        &--number {
            @include size-res(320px, 80px);

            @include sp {
                margin: 0 auto;
                display: block;
                width: 320px;
                height: 80px;
            }
        }
    }
}

.nav {
    &_button {
        display: none;

        @include sp {
            display: block;
            width: auto;
            height: 100%;
            width: 64px;
            height: 64px;
            aspect-ratio: 1/1;
            // box-shadow: 0px 0px 10px #00000014;
            border: unset;
            outline: unset;
            z-index: 1000000;
            position: fixed;
            right: 16px;
            top: 24px;
            background-color: white;
            border-radius: 50%;

            &--line {
                width: 18px;
                height: 1.5px;
                border-radius: 0.75px;
                background-color: #639B54;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: width .4s ease, transform .4s ease, opacity .4s ease;

                &:nth-child(1) {
                    transform: translate(-50%, calc(-50% - 5px));
                }

                &:nth-child(2) {
                    opacity: 1;
                    width: 13px;
                    transform: translate(calc(-50% - 2.5px), -50%);
                }

                &:nth-child(3) {
                    transform: translate(calc(-50% - 5.5px), calc(-50% + 5px));
                    width: 7px;
                }

                &.is-open {
                    width: 18.7px;

                    &:nth-child(1) {
                        transform: translate(-50%, -50%) rotate(36deg);
                    }

                    &:nth-child(2) {
                        opacity: 0;
                    }

                    &:nth-child(3) {
                        transform: translate(-50%, -50%) rotate(-36deg);
                    }
                }
            }
        }

    }
}

.flow {
    &_list {
        display: grid;
        grid-template-columns: repeat(7, max-content);
        gap: res(4px);

        @include pc {
            justify-content: flex-end;
        }

        @include sp {
            grid-template-columns: 100%;
        }
    }

    &_items {
        padding: res(24px) res(36px);
        display: grid;
        grid-template-columns: 100%;
        grid-auto-rows: max-content;
        gap: 16px;

        @include pc {
            width: res(120px);
            min-height: res(540px);
            justify-items: center;
        }

        @include sp {
            grid-template-columns: max-content 1fr;
            align-items: center;
            gap: 16px;
            width: 100%;
        }

        &:nth-child(odd) {
            background-color: #639B54;
        }

        &:nth-child(even) {
            background-color: #90CE06;
        }
    }

    &_content {
        display: grid;
        grid-template-columns: 100%;
        justify-items: center;
        gap: 8px;

        @include sp {
            grid-template-columns: max-content max-content 1fr;
            align-items: center;
            justify-items: flex-start;
        }
    }

    &_text {
        color: white;
        font-size: 22px;
        font-weight: 600;

        @include pc {
            text-align: center;
        }

        @include sp {
            font-size: 16px;
        }

        &--number {}

        &--body {
            @include pc {
                writing-mode: vertical-rl;
            }
        }
    }

    &_img {
        &--icon {
            @include size-res(48px);
        }

        &--slash {
            @include size-res(25px, 15px);
        }
    }
}